import * as Yup from "yup";
import moment from "moment";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";

import Order from "./Order";
import Login from "./Login";
import Register from "./Register";

import Form from "components/Form";
import Alert from "components/Alert";
import Modal from "components/Modal";
import Radio from "components/Radio";
import Toast from "components/Toast";
import Notice from "components/Notice";
import ProgressStepper from "components/ProgressStepper";
import { EditIcon, PlusIcon, CloseIcon } from "components/Icons";

import {
  updateAddress,
  deleteAddress,
  getMenus,
  getSetting,
} from "api/request";
import { createNewOrder, addAddress, getCustomerDetail } from "api/request";

import { isTimeInRange, getInitialValues } from "helper/form.helper";
import { isEmpty, getSchema, getMaxMinTime } from "helper/form.helper";

import { setUser } from "user/user.action";
import { setItems } from "order/order.action";
import { maxPhoneLength } from "app/app.constant";
import { Footer } from "App";
import useResize from "hooks/useResize";

const Auth = ({ onSuccess }) => {
  return (
    <div style={{ height: "80vh" }} className="overflow-y-scroll">
      <Login onSuccess={onSuccess} />
      <div className="mt-10">
        <Register onSuccess={onSuccess} isHalfSize={false} />
      </div>
    </div>
  );
};

const getDateAfterMinutes = (minutes) => {
  return new Date(new Date().getTime() + minutes * 60000);
};

export default function Main() {
  const dispatch = useDispatch();
  const { branch, orders } = useSelector((state) => state.order);
  const slots = JSON.parse(branch?.slots);
  const maxMinTime = getMaxMinTime(slots);

  const [menuLoading, setMenuLoading] = useState(false); // menu loading
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [foodAlergy, setFoodAlergy] = useState("");
  const [orderEmpty, setOrderEmpty] = useState(false);
  const [placedOrder, setPlacedOrder] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [specialRequest, setSpecialRequest] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [paymentType, setPaymentType] = useState("");
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isAddressDelete, setAddressDelete] = useState(false);
  const [address, setAddress] = useState({}); // for edit purpose
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [addressIndex, setAddressIndex] = useState(0); // for edit and delete purpose
  const [deliveryTime, setDeliveryTime] = useState(getDateAfterMinutes(45));
  const [collectionTime, setCollectionTime] = useState(getDateAfterMinutes(45));

  const [isCartVisible, setCartVisible] = useState(false); // only when resize to mobile view (sm)
  const isAuthenticated = localStorage.getItem("token") !== null;

  const [cardDisable, setCardDisable] = useState(false);
  const [cashDisable, setCashDisable] = useState(false);
  const [collectionDisable, setCollectionDisable] = useState(false);
  const [deliveryDisable, setDeliveryDisable] = useState(false);

  const { isSm } = useResize();
  const foodAlergyRef = React.createRef();
  const specialRequestRef = React.createRef();

  const [minimumOrder, setMinimumOrder] = useState(0);
  const [minOrderAlert, setMinOrderAlert] = useState(false);
  const [collectionBufferTime, setCollectionBufferTime] = useState(45); // time the customer has to wait before placing next order
  const [deliveryBufferTime, setDeliveryBufferTime] = useState(45); // time the customer has to wait before placing next order

  const formFields = [
    {
      name: "postal_code",
      placeholder: "Postal code *",
      initialValue: address?.postal_code,
      required: true,
      validation: Yup.string().required("Postal code is required"),
    },
    {
      name: "city",
      placeholder: "City *",
      initialValue: address?.city,
      required: true,
      validation: Yup.string().required("City is required"),
    },
    {
      name: "street_address",
      placeholder: "Street name *",
      initialValue: address?.street_address,
      required: true,
      validation: Yup.string().required("Street name is required"),
    },
    {
      name: "secondary_phone",
      placeholder: "Secondary phone",
      initialValue: address?.secondary_phone,
      required: false,
      validation: Yup.string()
        .matches(/^[0-9]+$/, "Phone number must be only digits")
        .min(
          maxPhoneLength,
          `Phone number must be exactly ${maxPhoneLength} digits`
        )
        .max(
          maxPhoneLength,
          `Phone number must be exactly ${maxPhoneLength} digits`
        ),
    },
  ];

  const fetchUser = async () => {
    const { data, error } = await getCustomerDetail();

    if (!error && data) {
      dispatch(setUser(data.message));
    }
  };

  const fetchMenus = async () => {
    setMenuLoading(true);
    const { error, data } = await getMenus();

    if (!error) {
      setMenus(data.message);
    }

    setMenuLoading(false);
  };

  const fetchCustomerDetail = async () => {
    const { data, error } = await getCustomerDetail();

    if (!error && data) {
      setAddresses(JSON.parse(data.message.address));
    }
  };

  const fetchSetting = async () => {
    const { data, error } = await getSetting(branch.id);

    if (!error) {
      const {
        card,
        cash,
        collection,
        delivery,
        delivery_time,
        collection_time,
        minimum_order,
      } = data.message;

      setCashDisable(cash === 0);
      setCardDisable(card === 0);
      setCollectionDisable(collection === 0);
      setDeliveryDisable(delivery === 0);

      setDeliveryBufferTime(delivery_time);
      setCollectionBufferTime(collection_time);
      setMinimumOrder(minimum_order);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  useEffect(() => {
    isAuthenticated && setActiveStep(1);
  }, [isAuthenticated]);

  useEffect(() => {
    fetchMenus();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCustomerDetail();
    }
  }, [isAuthenticated]);

  const handleUpdateItem = (index, qty) => {
    orders[index].qty = +qty;
    return dispatch(setItems([...orders]));
  };

  const handleAddItem = (item) => {
    const index = orders.findIndex((i) => i.menu_id === item.menu_id);
    if (index === -1) {
      item.qty = 1;
      return dispatch(setItems([...orders, item]));
    }

    orders[index].qty += 1;

    return dispatch(setItems([...orders]));
  };

  const handleDeleteItem = (item_id) => {
    const filteredOrders = orders.filter((order) => order.menu_id !== item_id);

    return dispatch(setItems(filteredOrders));
  };

  const getTotal = (carts) => {
    return carts.reduce((acc, curr) => {
      return acc + curr.price * curr.qty;
    }, 0);
  };

  const getTotalInFormat = (carts) => {
    return ccyFormat(getTotal(carts));
  };

  const ccyFormat = (num) => {
    return `${process.env.REACT_APP_CURRENCY}${num.toFixed(2)}`;
  };

  const getVAT = (carts) => {
    return ccyFormat(
      carts.reduce(
        (sum, i) => (i.vat_percentage / 100) * i.price * i.qty + sum,
        0
      )
    );
  };

  const getItemsInIdAndQty = (items) => {
    // we need to send items in array of id and qty
    return JSON.stringify(
      items.map((item) => {
        return {
          id: item.menu_id,
          qty: item.qty,
        };
      })
    );
  };

  const onOrderItem = async () => {
    if (orders.length === 0) {
      setPlacedOrder(false);
      return Toast.error("Please select items to order");
    }

    setLoading(true);
    let payload = {
      branch_id: branch.id,
      payment_method: paymentType,
      payment_type: paymentMethod, // because of naming issue, payment type has to be payment method and viceversa
      food_ids: getItemsInIdAndQty(orders),
      service_charge: 0,
      discount: 0,
    };

    if (paymentType === "delivery") {
      payload.delivery_time = moment(deliveryTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      payload.address = JSON.stringify(selectedAddress);
    }

    if (paymentType === "collection") {
      payload.collection_time = moment(collectionTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    if (!!foodAlergy || !!specialRequest) {
      payload.note_for_chef = specialRequest + "**Alergy** " + foodAlergy;
    }

    const { error } = await createNewOrder(payload);

    if (error) {
      setLoading(false);
      setConfirmModalOpen(false);
      return alert("There was a problem while creating your order");
    }

    // order is created

    setConfirmModalOpen(false);
    setOrderSuccess(true);
    setLoading(false);
    dispatch(setItems([])); // clearing the cart items
    return Toast.success("Your order has been placed successfully!");
  };

  const onAddAddress = async (address) => {
    const { data, error } = await addAddress(address);

    if (error) {
      setAddressModalOpen(false);

      return Toast.error("There was a problem while adding your address");
    }

    setAddresses([...addresses, data.message]);
    fetchCustomerDetail();
    setAddressModalOpen(false);

    return Toast.success("Address added successfully!");
  };

  const onUpdateAddress = async (address) => {
    const { error } = await updateAddress(addressIndex, address);

    if (error) {
      setAddressModalOpen(false);

      return Toast.error("There was a problem while updating your address");
    }

    fetchCustomerDetail();
    setAddressModalOpen(false);

    return Toast.success("Address updated successfully!");
  };

  const handleAddressDelete = async () => {
    const { error } = await deleteAddress(addressIndex);

    if (error) {
      setAddressDelete(false);

      return Toast.error("There was a problem while deleting your address");
    }

    fetchCustomerDetail();
    setAddressDelete(false);

    return Toast.success("Address deleted successfully!");
  };

  const onEditClick = (index, address) => {
    setAddressModalOpen(true);
    setEditMode(true);
    setAddress(address);
    setAddressIndex(index);
  };

  const onDeleteClick = (index) => {
    setAddressIndex(index);
    setAddressDelete(true);
  };

  const handleSubmit = (values) => {
    if (editMode) {
      return onUpdateAddress(values);
    }

    return onAddAddress(values);
  };

  const handleOrderCreate = () => {
    if (paymentType === "delivery") {
      if (isEmpty(selectedAddress)) {
        setActiveStep(1);
        setConfirmModalOpen(false);

        return Toast.info("Please select your delivery address");
      }
    }

    setConfirmModalOpen(false);
    return onOrderItem();
  };

  const onPlaceOrder = () => {
    setCartVisible(false);
    setFoodAlergy(foodAlergyRef.current.value);
    setSpecialRequest(specialRequestRef.current.value);

    if (orders.length === 0) {
      return setOrderEmpty(true);
    }

    setPlacedOrder(true);

    if (!isAuthenticated) {
      return setActiveStep(0);
    }

    if (activeStep > 0) {
      return setActiveStep(activeStep);
    }

    return setActiveStep(1);
  };

  const handleAddressNext = () => {
    if (isEmpty(paymentType)) {
      return Toast.error("Please select your delivery type");
    }

    if (paymentType === "delivery") {
      if (isEmpty(selectedAddress)) {
        return Toast.error("Please select your delivery address");
      }

      if (!isTimeInRange(deliveryTime, maxMinTime[0], maxMinTime[1])) {
        return Toast.error(
          "Please select delivery time between " +
            maxMinTime[0] +
            " and " +
            maxMinTime[1]
        );
      }

      // function to check minimum order
      console.log("get total", getTotal(orders));
      console.log("get min", minimumOrder);
      if (getTotal(orders) < minimumOrder) {
        return setMinOrderAlert(true);
      }

      return setActiveStep(2);
    }

    if (!isTimeInRange(collectionTime, maxMinTime[0], maxMinTime[1])) {
      return Toast.error(
        "Please select collection time between " +
          maxMinTime[0] +
          " and " +
          maxMinTime[1]
      );
    }

    return setActiveStep(2);
  };

  const Delivery = () => {
    return (
      <div className="h-full flex flex-col">
        <label className="text-red-light font-balo text-xl">
          How would you like to be served?
        </label>

        {isTimeInRange(deliveryTime, maxMinTime[0], maxMinTime[1]) ? (
          <>
            <span className="mt-2">
              <Notice
                message={`Order is open from ${maxMinTime[0]} to ${maxMinTime[1]}`}
              />
            </span>
            {!collectionDisable && (
              <div className="flex flex-col mt-6">
                <div className="flex flex-row items-center">
                  <Radio
                    name="delivery"
                    value="collection"
                    className="mb-5 col-span-1"
                    onClick={() => setPaymentType("collection")}
                    checked={paymentType === "collection"}
                  />

                  <span className="flex flex-col justify-center ml-3">
                    <label className="font-popin font-semibold ">
                      I will collect my order
                    </label>
                    <label className="text-sm text-gray-dark font-popin ">
                      Collection time
                    </label>
                  </span>

                  <span>
                    <DatePicker
                      selected={collectionTime}
                      showTimeSelect
                      showTimeSelectOnly
                      className="border-1 border-green-primary rounded-sm p-2 ml-2 text-green-primary font-semibold w-auto sm:w-32"
                      onChange={(date) => setCollectionTime(date)}
                      timeIntervals={collectionBufferTime}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </span>
                </div>
              </div>
            )}

            {!deliveryDisable && (
              <div className="flex flex-col mt-4">
                <div className="flex flex-row items-center">
                  <Radio
                    name="delivery"
                    value="delivery"
                    className="mb-5"
                    onClick={() => setPaymentType("delivery")}
                    checked={paymentType === "delivery"}
                  />

                  <span className="flex flex-col justify-center ml-3">
                    <label className=" font-popin font-semibold">
                      Please deliver it to me
                    </label>
                    <label className="text-sm text-gray-dark font-popin">
                      Delivery time
                    </label>
                  </span>

                  <span>
                    <DatePicker
                      selected={deliveryTime}
                      showTimeSelect
                      showTimeSelectOnly
                      className="border-1 border-green-primary rounded-sm p-2 ml-2 text-green-primary font-semibold sm:w-32"
                      onChange={(date) => setDeliveryTime(date)}
                      timeIntervals={deliveryBufferTime}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </span>
                </div>
              </div>
            )}

            {paymentType === "delivery" && (
              <span className="mt-5">
                <label className="text-red-light font-balo text-xl mt-4">
                  Delivery Address
                </label>

                <div className="grid grid-cols-4 sm:grid-cols-2 grid-flow-row gap-4">
                  {addresses.map((address, index) => {
                    const isChecked =
                      JSON.stringify(address) ===
                      JSON.stringify(selectedAddress);

                    return (
                      <span
                        onClick={() => setSelectedAddress(address)}
                        className={`flex flex-col justify-center pb-4 drop-shadow border-2 rounded-md items-start text-sm cursor-pointer ${
                          isChecked
                            ? "border-green-primary"
                            : "border-coolGray-100"
                        }  ml-2 py-2 my-3 h-40 relative`}
                        key={index}
                      >
                        <span className="flex flex-col  flex-wrap rounded font-popin font-semibold text-gray-darkest px-5 ">
                          <label>{address?.street_address}</label>
                          <label className="text-clip overflow-hidden">
                            {address?.postal_code} {address?.city}
                          </label>
                          <label>{address?.secondary_phone}</label>
                        </span>

                        <span className="bg-gray-darkest w-full h-9 bottom-0 absolute rounded-b">
                          <span className="flex flex-row items-center">
                            <span className="flex flex-row justify-evenly mt-2 w-1/2">
                              <EditIcon
                                onClick={() => onEditClick(index, address)}
                              />

                              {addresses.length > 1 && (
                                <CloseIcon
                                  onClick={() => onDeleteClick(index)}
                                />
                              )}
                            </span>
                          </span>
                        </span>
                        {isChecked && (
                          <label className="text-white font-popin text-right absolute bottom-2 right-3 text-sm">
                            Primary
                          </label>
                        )}
                      </span>
                    );
                  })}
                  <span
                    className="flex flex-col justify-center drop-shadow border-2 rounded items-center text-sm border-coolGray-100 ml-2 py-2 my-3 h-40 relative cursor-pointer"
                    onClick={() => {
                      setAddress({});
                      setAddressModalOpen(true);
                    }}
                  >
                    <PlusIcon />
                    <label className="font-popin text-center w-full text-sm">
                      Add new address
                    </label>
                  </span>
                </div>
              </span>
            )}

            <span className="flex flex-row justify-around mt-10 items-center">
              <button
                onClick={() => setPlacedOrder(false)}
                className="btn-dark w-full ml-2 mr-2"
              >
                Back
              </button>
              <button
                onClick={handleAddressNext}
                className="btn-green w-full ml-3"
              >
                Next
              </button>
            </span>
          </>
        ) : (
          <span className="mt-5">
            <Notice message={"Restaurant is closed at this time"} />
          </span>
        )}

        <div className="sm:h-16 h-0" />
      </div>
    );
  };

  const Payment = () => {
    return (
      <div>
        <label className="text-red-light text-xl font-balo">
          Choose a payment method
        </label>

        {!cashDisable && (
          <div className="flex flex-col mt-6 ">
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => setPaymentMethod("cash")}
            >
              <Radio
                name="paymentMethod"
                onClick={() => setPaymentMethod("cash")}
                checked={paymentMethod === "cash"}
              />

              <span className="flex flex-col justify-center ml-3">
                <label className=" font-semibold font-popin text-gray-darkest">
                  Cash
                </label>
              </span>
            </div>
          </div>
        )}

        {!cardDisable && (
          <div className="flex flex-col mt-4">
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => setPaymentMethod("card")}
            >
              <Radio
                name="paymentMethod"
                onClick={() => setPaymentMethod("card")}
                checked={paymentMethod === "card"}
              />

              <span className="flex flex-col justify-center ml-3">
                <label className=" font-semibold font-popin text-gray-darkest">
                  Card
                </label>
              </span>
            </div>
          </div>
        )}

        <span className="flex flex-row justify-around mt-10 items-center">
          <button
            onClick={() => setActiveStep(1)}
            className="btn-dark w-full ml-2 mr-2"
          >
            Back
          </button>
          <button
            onClick={() => setActiveStep(3)}
            className="btn-green w-full ml-3"
          >
            Next
          </button>
        </span>
      </div>
    );
  };

  const OrderSuccess = () => {
    const history = useHistory();
    const navigateToHome = () => {
      return (window.location.href = "/order");
    };

    const checkOrderStatus = () => {
      return history.push("/profile/orderHistory");
    };

    return (
      <div className="flex flex-col p-9">
        <span className="flex flex-col items-center sm:items-start">
          <label className="text-red-light font-semibold text-xl">
            Order placed successfully
          </label>

          <label className="mt-5 text-gray-darkest font-semibold font-popin">
            Thank you for choosing Alootama
          </label>
        </span>

        <span className="mt-5 text-gray-darkest leading-9">
          1. Your order has been placed successfully.
          <br /> 2. You will shortly receive a confirmation email after your
          order.
        </span>
        <span className="flex flex-row justify-around mt-10 items-center sm:flex-col">
          <button
            onClick={checkOrderStatus}
            className="btn-dark w-full ml-2 mr-2 sm:text-sm sm:h-10 sm:ml-0 sm:mr-0"
          >
            Check Order Status
          </button>
          <button
            onClick={navigateToHome}
            className="btn-green w-full ml-3 sm:text-sm sm:h-10 sm:ml-0 sm:mt-4"
          >
            Take new order
          </button>
        </span>
      </div>
    );
  };

  const Summary = () => {
    const onNext = () => {
      setConfirmModalOpen(true);
    };

    return (
      <div className="flex flex-col">
        <label className="text-red-light font-balo text-xl">
          Order summary
        </label>

        <div className="ml-5 flex flex-col mt-5">
          <label className="font-semibold font-popin text-gray-darkest">
            Order Delivery address:
          </label>

          <span className="flex flex-col ml-5 leading-10 mt-3 mb-3 font-popin text-sm">
            {selectedAddress?.street_address} {selectedAddress.city}{" "}
            {selectedAddress?.postal_code}
          </span>
          <label className="font-semibold  font-popin text-gray-darkest">
            Delivery time:{" "}
            {paymentType === "collection"
              ? moment(collectionTime).format("HH:mm")
              : moment(deliveryTime).format("HH:mm")}
          </label>
          <label className="font-semibold  mt-3 font-popin text-gray-darkest">
            Payment: {paymentMethod.toUpperCase()}
          </label>
        </div>

        <span className="flex flex-row justify-around mt-10 items-center">
          <button
            onClick={() => setActiveStep(2)}
            className="btn-dark w-full ml-2 mr-2"
          >
            Back
          </button>
          <button onClick={onNext} className="btn-green w-full ml-3">
            Next
          </button>
        </span>
      </div>
    );
  };

  const StepperBlock = () => {
    switch (activeStep) {
      case 0:
        return (
          <Auth
            onSuccess={() => {
              fetchUser();
              setActiveStep(1);
            }}
          />
        );
      case 1:
        return <Delivery />;

      case 2:
        return <Payment />;

      case 3:
        return <Summary />;
      default:
        return <Payment />;
    }
  };

  const Cart = ({ carts }) => {
    console.log("called");
    return (
      <div className=" flex-col col-span-1 bg-white rounded-sm filter drop-shadow-xl p-1 items-center">
        <h3 className="text-center text-red-light mt-5 text-lg">Your orders</h3>
        {carts.length === 0 && (
          <label className="text-center font-popin ml-2">
            You have no orders yet.
          </label>
        )}
        <div style={{ maxHeight: "40vh" }} className="overflow-y-scroll">
          {carts?.map((item, index) => (
            <div
              key={index}
              className="flex flex-row justify-around items-center flex-wrap mt-5 p-1 box-border rounded border-b-2 border-warmGray-200"
            >
              <div className="flex flex-col">
                <label className="font-popin font-semibold">{item.name}</label>
                {!isSm && (
                  <label
                    htmlFor="description"
                    className="font-popin font-regular text-xs w-40"
                  >
                    {item.description}
                  </label>
                )}
              </div>
              <span>
                <input
                  type="number"
                  className="w-16 text-center border-2 border-gray-light rounded-sm"
                  max="5"
                  min="1"
                  onChange={(e) => handleUpdateItem(index, e.target.value)}
                  value={item.qty}
                  defaultValue="1"
                />
              </span>
              <label className="font-semibold">
                {process.env.REACT_APP_CURRENCY}
                {item.price}
              </label>
              <span
                onClick={() => handleDeleteItem(item.menu_id)}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40.87"
                  height="40.87"
                  viewBox="0 0 32.87 32.87"
                >
                  <path
                    id="plus"
                    d="M9.5,0A9.5,9.5,0,1,0,19,9.5,9.511,9.511,0,0,0,9.5,0Zm4.156,10.292H10.292v3.365a.792.792,0,0,1-1.583,0V10.292H5.344a.792.792,0,0,1,0-1.583H8.708V5.344a.792.792,0,0,1,1.583,0V8.708h3.365a.792.792,0,0,1,0,1.583Zm0,0"
                    transform="translate(13.435) rotate(45)"
                    fill="#2a7a83"
                  />
                </svg>
              </span>
            </div>
          ))}

          {carts.length > 0 && (
            <span className="text-center text-green-primary font-semibold flex items-center justify-start pl-5 pt-2">
              <label
                className="text-center cursor-pointer"
                onClick={() => {
                  setPlacedOrder(false);
                  setCartVisible(false);
                }}
              >
                Add More Items +
              </label>
            </span>
          )}
        </div>
        <div className="flex flex-row justify-evenly items-center mt-5 box-border rounded border-b-2 border-warmGray-200">
          <span className="font-popin font-semibold">
            Net <br />
            VAT
          </span>
          <span />

          <label className="font-semibold ">{getVAT(carts)}</label>
        </div>
        <div className="flex flex-row justify-evenly items-center mt-5 box-border rounded border-b-2 border-warmGray-200">
          <span className="font-popin font-semibold">Total</span>
          <span />

          <label className="font-semibold">{getTotalInFormat(carts)}</label>
        </div>
        <div className="flex flex-col w-full p-4">
          <label className="font-semibold font-popin">
            Any special request?
          </label>
          <input
            type="text"
            ref={specialRequestRef}
            name="specialRequest"
            // onChange={(e) => {
            //   e.preventDefault();
            //   setSpecialRequest(e.target.value);
            // }}
            className="form-control modal-input bg-white border-2 border-warmGray-200 p-2 rounded mt-2 focus:outline-none w-full md:w-auto"
          />

          <span className="flex flex-col mt-2">
            <label className="text-red-light font-semibold font-popin">
              Any food alergy?
            </label>
            <input
              type="text"
              name="foodAlergy"
              ref={foodAlergyRef}
              placeholder="Note Down if you are alergic to anything"
              // onChange={(e) => setFoodAlergy(e.target.value)}
              className="bg-white border-2 border-warmGray-200 p-2 rounded mt-2 focus:outline-none font-popin"
            />
          </span>

          {!placedOrder && (
            <button
              className="btn-green w-1/2 sm:w-full mt-5 h-10 items-center self-center"
              onClick={onPlaceOrder}
            >
              Place Order
            </button>
          )}
        </div>
      </div>
    );
  };

  const MemoizedCart = React.memo(Cart, (prevProps, nextProps) => {
    return prevProps.carts.length === nextProps.carts.length;
  });

  return (
    <>
      <Helmet>
        <title>Order</title>
      </Helmet>
      <div className="relative bg-gray-lightest p-5 sm:p-2 grid grid-cols-3 sm:grid-cols-1 lg:grid-cols-1 lg:gap-0 gap-10 sm:gap-0 max-h-screen overflow-hidden sm:overflow-visible lg:overflow-visible h-full">
        <div className="flex flex-col col-span-2 px-7 pt-5 pb-10 sm:pl-5 sm:pr-5 bg-white rounded-sm filter drop-shadow-xl sm:w-full">
          {orderSuccess ? (
            <OrderSuccess />
          ) : placedOrder ? (
            <>
              <ProgressStepper activeStep={activeStep} />
              <StepperBlock />
            </>
          ) : (
            <Order
              menus={menus}
              onAddOrderItem={handleAddItem}
              loading={menuLoading}
            />
          )}
        </div>

        {isSm ? (
          <div className="fixed bottom-0 right-0 bg-white w-full h-20 flex flex-row justify-between items-center border-t-2 border-coolGray-200 px-7">
            <span className="text-sm font-popin text-green-primary font-semibold">
              ({orders.length} items) {getTotalInFormat(orders)}
            </span>
            <button
              className="btn-green p-2"
              onClick={() => setCartVisible(true)}
            >
              View cart
            </button>
          </div>
        ) : !orderSuccess ? (
          <MemoizedCart carts={orders} />
        ) : null}
      </div>

      <Modal open={addressModalOpen} setOpen={setAddressModalOpen}>
        <Form
          isEditable={true}
          initialValues={getInitialValues(formFields)}
          Schema={getSchema(formFields)}
          fields={formFields}
          loading={true}
          // loading={loading}
          onSubmit={handleSubmit}
          buttonTitle={"Save"}
        />
      </Modal>

      <Alert
        open={confirmModalOpen}
        message=" Do you really want to take an order?"
        onPress={handleOrderCreate}
        onCancel={() => setConfirmModalOpen(false)}
      />
      <Alert
        open={isAddressDelete}
        message="Do you really want to delete this address?"
        onPress={handleAddressDelete}
        onCancel={() => setAddressDelete(false)}
      />
      <Alert
        open={orderEmpty}
        message={"Please add items to your order."}
        hasCancel={false}
        onCancel={() => setOrderEmpty(false)}
      />

      <Alert
        open={minOrderAlert}
        message={`Minimum order amount is ${process.env.REACT_APP_CURRENCY}${minimumOrder}`}
        hasCancel={false}
        onCancel={() => setMinOrderAlert(false)}
      />

      <Modal
        open={loading}
        setOpen={() => setLoading(false)}
        hasCloseIcon={false}
      >
        <div className="h-32 flex flex-col justify-center items-center">
          <FadeLoader loading={true} size={40} height={10} />
          <label className="font-popin font-regular">
            Creating your order..
          </label>
        </div>
      </Modal>

      <Modal
        open={isCartVisible}
        hasCloseIcon
        setOpen={() => setCartVisible(false)}
        hasCancel={true}
      >
        <MemoizedCart carts={orders} />
      </Modal>
    </>
  );
}
