// @ts-nocheck
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const InputField = ({ name, placeholder, type, className }) => {
  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }) => (
        <div className={`flex flex-col my-2 ${className}`}>
          <label
            className="block text-gray-700 text-sm font-popin text-gray-darkest mb-2"
            htmlFor="username"
          >
            {placeholder}
          </label>
          <input
            className="shadow appearance-none border border-gray-dark rounded w-full py-3 px-3 text-gray-darkest leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type={type}
            {...field}
          />

          <ErrorMessage
            name={name}
            component="span"
            className="text-red-light"
          />
        </div>
      )}
    </Field>
  );
};

const MyForm = ({
  isEditable,
  initialValues,
  Schema,
  onSubmit,
  fields,
  hasCaptcha,
  captchaRef,
  captchEmpty,
  onVerifyCaptcha,
  onExpireCaptcha,
  buttonTitle,
}) => {
  return (
    <Formik
      enableReinitialize={isEditable}
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      <Form className="flex flex-col mt-5 py-5 px-10 rounded-sm">
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <InputField
              name={field.name}
              type={field?.type}
              disabled={field?.disabled}
              placeholder={field.placeholder}
              required={field.required}
            />
          </React.Fragment>
        ))}

        {hasCaptcha && (
          <span className="flex flex-col items-start justify-start w-full mt-5">
            <HCaptcha
              ref={captchaRef}
              sitekey={process.env.REACT_APP_SITE_KEY}
              onVerify={onVerifyCaptcha}
              onExpire={onExpireCaptcha}
            />
            {captchEmpty && (
              <span>
                <label className="text-red-light">This is required</label>
              </span>
            )}
          </span>
        )}
        <button
          type="submit"
          variant="contained"
          color="primary"
          className="btn-green mt-5 align-bottom p-2 sm:self-center rounded-md sm:w-full"
        >
          {buttonTitle}
        </button>
      </Form>
    </Formik>
  );
};

MyForm.defaultProps = {
  buttonTitle: "Continue",
};

export default MyForm;
