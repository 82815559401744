import * as http from "./api.http";

export const getBranches = () => {
  return http.get("/branch");
};

export const getSetting = (branchId) => {
  return http.get(`/branch/${branchId}/setting`);
};

export const login = (data) => {
  return http.post("/login", data);
};

export const register = (data) => {
  return http.post("/register", data);
};

export const forgetPassword = (data) => {
  return http.post("/forget", data);
};

export const resetPassword = (data) => {
  return http.post("/reset", data);
};

export const getMenus = () => {
  return http.get("/menus");
};

export const createNewOrder = (data) => {
  return http.post("customer/order", data);
};

export const addAddress = (data) => {
  return http.post("/customer/address", data);
};

export const deleteAddress = (addressId) => {
  return http.remove(`/customer/address/${addressId}`);
};

export const updateAddress = (addressId, data) => {
  return http.patch(`/customer/address/${addressId}`, data);
};

export const getCustomerDetail = () => {
  return http.get("/customer");
};

export const updateCustomerDetail = (data) => {
  return http.patch("/customer", data);
};

export const getCurrentOrders = () => {
  return http.get("/customer/orders/current");
};

export const getPastOrders = (page = 1) => {
  return http.get("/customer/orders/past?page=" + page);
};

export const getOrder = (id) => {
  return http.get(`/customer/order/${id}`);
};

export const checkCaptcha = (token) => {
  return http.post("/siteverify", { capche: token });
};
