import React from "react";

const Alert = ({
  open,
  onPress,
  onCancel,
  message,
  hasCancel,
  cancelTitle,
}) => {
  return (
    <div
      style={{
        display: open ? "flex" : "none",
        position: "fixed",
        zIndex: 100,
        left: 0,
        top: 0,
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: "auto",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      role="dialog"
    >
      <div className="modal-content bg-white sm:p-5 p-10 items-center justify-center relative w-1/3 sm:w-5/6 rounded-sm">
        <span className="flex flex-col">
          <label className="font-semibold text-red-light text-center">
            {message}
          </label>

          {!hasCancel ? (
            <span className="flex items-center justify-center mt-5">
              <button className="btn-dark w-1/2" onClick={onCancel}>
                {cancelTitle}
              </button>
            </span>
          ) : (
            <span className="grid grid-cols-2 gap-3 mt-5">
              <button className="btn-dark w-full" onClick={onCancel}>
                No
              </button>

              <button className="btn-green w-full" onClick={onPress}>
                Yes
              </button>
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

Alert.defaultProps = {
  hasCancel: true,
  cancelTitle: "Ok",
};

export default Alert;
