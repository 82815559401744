import React from "react";

export default function Radio({
  value,
  name,
  checked,
  onChange,
  onClick,
  className,
}) {
  return (
    <div className={`flex items-center ${className}`} onClick={onClick}>
      <input
        type="radio"
        name={name}
        value={value}
        className="hidden"
        onChange={onChange}
        checked={checked}
      />
      <label for="radio1" className="flex items-center cursor-pointer text-xl">
        <span className="sm:w-4 sm:h-4 w-5 h-5 inline-block rounded-full border border-grey flex-no-shrink"></span>
      </label>
    </div>
  );
}
