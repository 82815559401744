import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import Toast from "components/Toast";

export default function Auth() {
  const history = useHistory();
  const lastPath = useLocation().pathname.split("/")[2];
  const [showOnlyLogin, setShowOnlyLogin] = useState(false);

  useEffect(() => {
    setShowOnlyLogin(lastPath === "login");
  }, [lastPath]);

  const scrollToTop = () => {
    return window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const onSuccess = () => {
    window.location.href = "/";
  };

  const onRegister = () => {
    Toast.success("Successfully registered.Please login to continue");

    scrollToTop();
    history.push("/auth");
  };

  return (
    <div className="container p-10 flex flex-col justify-center items-center">
      <div className="w-1/2 sm:w-full">
        <div>
          <Login onSuccess={onSuccess} />
        </div>

        {!showOnlyLogin && (
          <div className="mt-10">
            <Register onSuccess={onRegister} isHalfSize={false} />
          </div>
        )}
      </div>
    </div>
  );
}
