import * as Yup from "yup";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getInitialValues, getSchema } from "helper/form.helper";

import { getCustomerDetail, updateCustomerDetail } from "api/request";
import { addAddress, updateAddress, deleteAddress } from "api/request";
import { getOrder, getCurrentOrders, getPastOrders } from "api/request";

import {
  OrderIcon,
  EditIcon,
  PlusIcon,
  CloseIcon,
  LogoutIcon,
  AddressIcon,
  ProfileIcon,
} from "components/Icons";
import Alert from "components/Alert";
import Modal from "components/Modal";
import Form from "components/Form";
import Toast from "components/Toast";

import { setUser } from "user/user.action";
import { maxPhoneLength } from "app/app.constant";
import useResize from "hooks/useResize";

const Path = {
  address: "address",
  account: "account",
  orderHistory: "orderHistory",
};

export const getOrderStatus = (statusCode) => {
  if (statusCode === 0) return "Ordered";
  else if (statusCode === 1) return "Processing";
  else if (statusCode === 2) return "Pick Up";
  else if (statusCode === 3) return "Completed";
  else return "Cancelled";
};

const getNonEmptyValue = (value) => {
  if (!!value) return value;

  return "";
};

const getAddress = (address) => {
  const parsedAdress = JSON.parse(address);
  const streetAddress = getNonEmptyValue(parsedAdress?.street_address);
  const postalCode = getNonEmptyValue(parsedAdress?.postal_code);

  if (streetAddress && postalCode) {
    return `${streetAddress} , ${postalCode}`;
  }

  return "Unknown";
};

const OrderDetail = ({ order }) => {
  const { items, detail } = order;
  const { fname, lname, phone } = useSelector((state) => state.user);
  const getTotal = () => {
    return ccyFormat(
      items?.reduce((acc, curr) => {
        return acc + curr.price * curr.qty;
      }, 0)
    );
  };

  const ccyFormat = (num) => {
    return `${process.env.REACT_APP_CURRENCY}${num.toFixed(2)}`;
  };

  const getVAT = () => {
    return ccyFormat(
      items?.reduce((sum, i) => (i.rate / 100) * i.price * i.qty + sum, 0)
    );
  };

  const getFullName = () => {
    if (fname !== "" && lname !== "") {
      return `${fname} ${lname}`;
    }

    return "";
  };

  if (!items) {
    return <></>;
  }

  return (
    <div className=" flex-col col-span-1  rounded-sm  items-center min-h-full">
      <span className="flex flex-row justify-between items-center px-4 font-popin font-medium  mt-2">
        <label className="text-sm">Order Id: {detail?.bill_id}</label>
        <label className="text-sm">
          Order date: {moment(detail.order_date).format("DD MMM, YYYY")}
        </label>
      </span>
      <h3 className="text-center text-gray-darkest font-popin font-semibold mt-5 text-lg w-full bg-gray-lightest p-2">
        Order Details
      </h3>

      <div style={{ maxHeight: "30vh", overflowY: "scroll" }}>
        {items?.map((item, index) => (
          <div
            key={index}
            className=" flex flex-row justify-between flex-wrap mt-5 p-1 box-border rounded border-b-2 border-warmGray-200 w-full"
          >
            <label className="ml-5 font-popin text-gray-darkest w-1/3">
              {index + 1}. {item.name}
            </label>

            <span className=" bg-gray-darkest rounded-md px-4 max-h-6">
              <label className="font-popin font-semibold text-white text-xs">
                {item.qty}
              </label>
            </span>

            <label className="font-semibold ml-5 w-1/4">
              {process.env.REACT_APP_CURRENCY}
              {item.price}
            </label>
          </div>
        ))}
      </div>

      <div className="flex flex-row justify-evenly items-center mt-5 box-border rounded border-b-2 border-warmGray-200">
        <span className="font-popin font-semibold ml w-1/2">
          Net <br />
          VAT
        </span>
        <label className="font-semibold mr-2">{getVAT()}</label>
      </div>
      <div className="flex flex-row justify-evenly items-center mt-5 box-border rounded border-b-2 border-warmGray-200">
        <span className="font-popin font-semibold ml text-xl w-1/2">Total</span>
        <label className="font-semibold mr-2 text-xl">{getTotal()}</label>
      </div>

      <h3 className="text-center text-gray-darkest font-semibold  font-popin mt-5 text-lg w-full bg-gray-lightest p-2">
        {detail.payment_type === "delivery" ? "Delivery Details" : "Collection"}
      </h3>
      {detail.payment_type === "delivery" && (
        <div className="flex flex-col font-popin leading-8 p-4 text-gray-darkest font-semibold">
          <label>Name: {getFullName()}</label>
          <label>Contact: {phone}</label>
          <label>Location: {getAddress(detail.address)}</label>
        </div>
      )}
    </div>
  );
};

const Profile = () => {
  const [addressModal, setAddressModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});
  const [addressIndex, setAddressIndex] = useState(0);
  const [isEditMode, setEditMode] = useState(false);
  const [menu, setMenu] = useState("account");
  const [isLogoutConfirm, setLogoutConfirm] = useState(false);
  const [isAddressDelete, setAddressDelete] = useState(false);

  const [addressError, setAddressError] = useState(false);

  const [currentOrders, setCurrentOrders] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(true);
  const lastPath = useLocation().pathname.split("/")[2];

  const { isSm } = useResize();
  const [orderDetailOpen, setOrderDetailOpen] = useState(false); // modal for sm size(mobile)

  useEffect(() => {
    setMenu(!lastPath ? "account" : lastPath);
  }, [lastPath]);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchPastOrders();
    }
  }, [page]);

  const handleSubmit = (values) => {
    if (isEditMode) {
      return onUpdateAddress(values);
    }

    return onAddAddress(values);
  };

  const fetchCurrentOrders = async () => {
    const { data, error } = await getCurrentOrders();

    if (!error) {
      setCurrentOrders(data.message);
    }
  };

  const fetchPastOrders = async (page) => {
    const { data, error } = await getPastOrders(page);
    if (!error) {
      data.message.last_page === page
        ? setIsLastPage(true)
        : setIsLastPage(false);
      if (data.message.last_page === 1) {
        return setPastOrders(data.message.data);
      }

      if (data.message.data.length > 0) {
        return setPastOrders([...pastOrders, ...data.message.data]);
      }
    }
  };

  const fetchOrders = React.useCallback(() => {
    setLoading(true);

    Promise.all([fetchCurrentOrders(), fetchPastOrders(page)])
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onAddAddress = async (address) => {
    const { data, error } = await addAddress(address);

    if (error) {
      setAddressModal(false);

      return Toast.error("Address could not be added");
    }

    setAddresses([...addresses, data.message]);
    fetchAddresses();
    setAddressModal(false);

    return Toast.success("Address added successfully");
  };

  const onUpdateAddress = async (address) => {
    const { error } = await updateAddress(addressIndex, address);

    if (error) {
      setAddressModal(false);

      return Toast.error("Address could not be updated");
    }

    fetchAddresses();
    setAddressModal(false);

    return Toast.success("Address updated successfully");
  };

  const handleAddressDelete = async () => {
    const { error } = await deleteAddress(addressIndex);

    if (error) {
      setAddressDelete(false);

      return Toast.error("Address could not be deleted");
    }

    fetchAddresses();
    setAddressDelete(false);
    return Toast.success("Address deleted successfully");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");

    dispatch(setUser({}));
    return history.push("/");
  };

  const formFields = [
    {
      name: "postal_code",
      placeholder: "Postal code *",
      initialValue: address?.postal_code,
      required: true,
      validation: Yup.string().required("Postal code is required"),
    },
    {
      name: "city",
      placeholder: "City *",
      initialValue: address?.city,
      required: true,
      validation: Yup.string().required("City is required"),
    },
    {
      name: "street_address",
      placeholder: "Street Address *",
      initialValue: address?.street_address,
      required: true,
      validation: Yup.string().required("Street name is required"),
    },
    {
      name: "secondary_phone",
      placeholder: "Secondary phone",
      initialValue: address?.secondary_phone,
      required: false,
      validation: Yup.string()
        .matches(/^[0-9]+$/, "Phone number must be only digits")
        .min(
          maxPhoneLength,
          `Phone number must be exactly ${maxPhoneLength} digits`
        )
        .max(
          maxPhoneLength,
          `Phone number must be exactly ${maxPhoneLength} digits`
        ),
    },
  ];

  const fetchAddresses = async () => {
    const { data, error } = await getCustomerDetail();

    if (!error && data) {
      setAddresses(JSON.parse(data.message.address));
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const Orders = ({ orders, onOrderClick, showFooter }) => {
    return (
      <table className="text-left w-full my-table">
        <thead className="flex w-full border-2 border-warmGray-100">
          <tr className="flex w-full font-popin sm:text-sm text-gray-darkest">
            <th className="p-4 w-1/4">Bill No</th>
            <th className="p-4 w-1/4">Date</th>
            <th className="p-4 w-1/4">Method</th>
            <th className="p-4 w-1/4">Status</th>
          </tr>
        </thead>
        <tbody
          className="bg-grey-light flex flex-col items-center justify-between overflow-y-scroll w-full mt-3"
          style={{ maxHeight: "50vh" }}
        >
          {orders.map((_order, index) => (
            <tr
              key={index}
              className="flex w-full cursor-pointer sm:text-sm"
              onClick={() => onOrderClick(_order)}
            >
              <td
                className={`p-4 sm:p-2 w-1/4 font-popin text-gray-darkest  ${
                  _order.bill_id === order?.detail?.bill_id ? "underline" : ""
                } `}
              >
                {_order.bill_id}
              </td>
              <td className="p-4 sm:p-2 w-1/4 font-popin text-gray-darkest">
                {moment(_order.order_date).format("YYYY-MM-DD")}
              </td>
              <td className="p-4 sm:p-2 w-1/4 font-popin text-gray-darkest">
                {_order.order_type}
              </td>
              <td className="p-4 sm:p-2 w-1/4 font-popin text-gray-darkest">
                {getOrderStatus(_order.status)}
              </td>
            </tr>
          ))}
          {showFooter && (
            <tr>
              <button
                className="btn-green p-3"
                onClick={() => setPage(page + 1)}
              >
                Show more
              </button>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const OrderHistory = () => {
    const [orderType, setOrderType] = useState("current");
    const onOrderClick = async (order) => {
      let { error, data } = await getOrder(order.bill_id);

      if (isSm) {
        setOrderDetailOpen(true);
      }

      if (!error) {
        data.message.detail = order;
        setOrder(data.message);
      }
    };

    const borderClass = "border-b-2 border-blue-400 opacity-100";

    const items = orderType === "current" ? currentOrders : pastOrders;

    return (
      <div className="mt-7">
        <h2 className="text-center text-xl">Order History</h2>
        <ul
          id="tabs"
          className="flex flex-row justify-around w-full px-1 pt-2 mt-5"
        >
          <li
            className={`px-4 py-2 -mb-px font-semibold text-gray-800 cursor-pointer ${
              orderType === "current" ? borderClass : ""
            } rounded-t opacity-50`}
          >
            <a
              id="default-tab"
              href="#first"
              onClick={() => setOrderType("current")}
            >
              Current Orders
            </a>
          </li>
          <li
            className={`px-4 py-2 font-semibold text-gray-800 cursor-pointer ${
              orderType === "past" ? borderClass : ""
            } rounded-t opacity-50`}
          >
            <a onClick={() => setOrderType("past")}>Past Orders</a>
          </li>
        </ul>
        <Orders
          orders={items}
          onOrderClick={onOrderClick}
          showFooter={orderType === "past" && !isLastPage}
        />
      </div>
    );
  };

  const Address = () => {
    const onEditClick = (index, address) => {
      setAddressModal(true);
      setEditMode(true);
      setAddress(address);
      setAddressIndex(index);
    };

    const onDeleteClick = (index) => {
      setAddressIndex(index);
      setAddressDelete(true);
    };

    return (
      <div className="mt-7">
        <h2 className="text-center text-xl">Delivery Address</h2>
        <div className="grid grid-cols-4 sm:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-4 pt-14 sm:pt-0">
          {addresses.map((address, index) => {
            return (
              <span
                className="flex flex-col justify-center pb-4 drop-shadow border-2 rounded items-start text-sm border-coolGray-100 ml-2 py-2 my-3 h-40 relative"
                key={index}
              >
                <span className="flex flex-col  flex-wrap rounded font-popin font-semibold text-gray-darkest px-5 ">
                  <label>{address?.street_address}</label>
                  <label>
                    {address?.postal_code} {address?.city}
                  </label>
                  <label>{address?.secondary_phone}</label>
                </span>
                <span className="bg-gray-darkest w-full h-9 bottom-0 absolute rounded-b">
                  <span className="flex flex-row justify-evenly mt-2 w-1/2 ">
                    <EditIcon onClick={() => onEditClick(index, address)} />

                    {addresses.length > 1 && (
                      <CloseIcon onClick={() => onDeleteClick(index)} />
                    )}
                  </span>
                </span>
              </span>
            );
          })}
          <span
            className="flex flex-col justify-center drop-shadow border-2 rounded items-center text-sm border-coolGray-100 ml-2 py-2 my-3 h-40 relative cursor-pointer"
            onClick={() => setAddressModal(true)}
          >
            <PlusIcon />
            <label className="font-popin text-center w-full text-sm">
              Add new address
            </label>
          </span>
        </div>
      </div>
    );
  };

  const getBorderClass = (type) => {
    return type === menu
      ? "border-green-primary border-1 w-48 sm:w-1/4 rounded sm:border-none"
      : "";
  };

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className="max-h-full w-full px-52 sm:py-5 sm:px-5 sm:flex sm:flex-col sm:justify-evenly grid grid-flow-col">
        <div className="sm:flex sm:justify-center sm:border-b-2 sm:border-warmGray-300 sm:py-2 col-span-1">
          <div className="flex flex-col sm:flex-row items-start justify-start leading-10 p-2 sm:gap-6 pt-24 sm:pt-0">
            <div
              className={`flex sm:flex-col items-center px-2 sm:px-0 mt-2 ${getBorderClass(
                Path.account
              )}`}
              onClick={() => history.push("/profile/account")}
            >
              <ProfileIcon />
              <span className="cursor-pointer font-popin ml-2 sm:text-sm w-max">
                Account
              </span>
            </div>
            <div
              className={`flex sm:flex-col items-center px-2 sm:px-0 mt-2 ${getBorderClass(
                Path.orderHistory
              )}`}
              onClick={() => history.push("/profile/orderHistory")}
            >
              <OrderIcon />
              <span className="cursor-pointer font-popin ml-2 sm:text-sm w-max">
                Orders
              </span>
            </div>
            <div
              className={`flex sm:flex-col items-center px-2 sm:px-0 mt-2 ${getBorderClass(
                Path.address
              )}`}
              onClick={() => history.push("/profile/address")}
            >
              <AddressIcon />
              <span className="cursor-pointer font-popin ml-2 sm:text-sm w-max">
                Address
              </span>
            </div>
            <div
              className="flex sm:flex-col items-center px-2 sm:px-0 mt-2"
              onClick={() => setLogoutConfirm(true)}
            >
              <LogoutIcon />
              <span className="cursor-pointer font-popin ml-2 sm:text-sm w-max">
                Logout
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-11 grid grid-flow-col sm:grid-flow-row">
          <div>
            {menu === Path.account && <Account />}
            {menu === Path.orderHistory && <OrderHistory />}
            {menu === Path.address && <Address />}
          </div>
          <div className="pt-28 sm:pt-0">
            {isSm ? (
              <Modal
                isSm={true}
                open={orderDetailOpen}
                setOpen={() => setOrderDetailOpen(false)}
              >
                <div className="border-1 mt-7 border-gray-lightest bg-white rounded-sm filter drop-shadow-xl p-1 items-center">
                  <OrderDetail order={order} />
                </div>
              </Modal>
            ) : (
              menu === "orderHistory" &&
              order?.items?.length > 0 && (
                <div className="border-1 ml-10 border-gray-lightest bg-white rounded-sm filter drop-shadow-xl p-1 items-center">
                  <OrderDetail order={order} />
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <Modal open={addressModal} setOpen={setAddressModal}>
        <Form
          isEditable={true}
          initialValues={getInitialValues(formFields)}
          Schema={getSchema(formFields)}
          fields={formFields}
          loading={true}
          onSubmit={handleSubmit}
          buttonTitle={"Save"}
        />
      </Modal>

      <Alert
        open={isAddressDelete}
        message="Do you really want to delete this address?"
        onPress={handleAddressDelete}
        onCancel={() => setAddressDelete(false)}
      />

      <Alert
        open={isLogoutConfirm}
        message="Do you really want to logout of this account?"
        onPress={handleLogout}
        onCancel={() => setLogoutConfirm(false)}
      />

      <Alert
        open={addressError}
        message={
          "There was some problem while doing this operation.Please try again"
        }
        hasCancel={false}
        onCancel={() => setAddressError(false)}
      />
    </>
  );
};

const Account = () => {
  const [user, setUser] = useState({});
  const [accountUpdateMessage, setAccountUpdateMessage] = useState("");

  const fetchCustomerDetail = async () => {
    const { data, error } = await getCustomerDetail();

    if (!error) {
      setUser(data?.message);
    }
  };

  useEffect(() => {
    fetchCustomerDetail();
  }, []);

  const formFields = [
    {
      name: "fname",
      placeholder: "First Name",
      initialValue: user?.fname,
      required: true,
      validation: Yup.string().required("First name is required"),
    },
    {
      name: "lname",
      placeholder: "Last Name",
      initialValue: user?.lname,
      required: true,
      validation: Yup.string().required("Last name is required"),
    },
    {
      name: "email",
      placeholder: "Email",
      initialValue: user?.email,
      required: true,
      disabled: true,
      validation: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    },
    {
      name: "phone",
      placeholder: "Phone Number",
      initialValue: user?.phone,
      required: true,
      disabled: true,
      validation: Yup.string()
        .required("Phone number is required")
        .matches(/^[0-9]+$/, "Phone number must be only digits")
        .min(
          maxPhoneLength,
          `Phone number must be exactly ${maxPhoneLength} digits`
        )
        .max(
          maxPhoneLength,
          `Phone number must be exactly ${maxPhoneLength} digits`
        ),
    },
  ];

  const getChangedValues = (values, initialValues) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value;

      if (hasChanged) {
        acc[key] = value;
      }

      return acc;
    }, {});
  };

  const handleSubmit = async (values) => {
    const changedValues = getChangedValues(values, user); // in future using this :todo
    let payload = values;

    const isEmailChanged = user.email !== values.email;

    if (!isEmailChanged) {
      delete payload.email;
    }

    const { error, data } = await updateCustomerDetail(payload);

    if (error || data.error) {
      return Toast.error(
        "There was some problem while updating your account.Please try again"
      );
    }

    fetchCustomerDetail();

    return Toast.success("Your account has been updated successfully");
  };

  return (
    <div className="mt-7">
      <h2 className="text-center text-xl">Account</h2>
      <Form
        isEditable={true}
        fields={formFields}
        Schema={getSchema(formFields)}
        initialValues={getInitialValues(formFields)}
        onSubmit={handleSubmit}
        buttonTitle={"Save"}
      />
      <Alert
        open={accountUpdateMessage !== ""}
        hasCancel={false}
        message={accountUpdateMessage}
        onCancel={() => setAccountUpdateMessage("")}
      />
    </div>
  );
};

export default Profile;
