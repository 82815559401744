import React from "react";
import { Stepper, Step } from "react-form-stepper";

export default function ProgressStepper({ children, activeStep }) {
  return (
    <div>
      <Stepper
        activeStep={activeStep}
        styleConfig={{
          activeBgColor: "#313a4b",
          completedBgColor: "#2A7A83",
        }}
      >
        <Step label="Account" />
        <Step label="Order" />
        <Step label="Payment" />
        <Step label="Final Check" />
      </Stepper>
    </div>
  );
}
