import { SET_BRANCH, SET_ITEMS } from "./order.type";

export const setBranch = (payload) => {
  return {
    type: SET_BRANCH,
    payload,
  };
};

export const setItems = (payload) => {
  return {
    type: SET_ITEMS,
    payload,
  };
};
