import React from "react";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

import Form from "components/Form";
import Toast from "components/Toast";

import { resetPassword } from "api/request";
import { getSchema, getInitialValues } from "helper/form.helper";
import { useHistory } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default function ResetPassword() {
  let query = useQuery();
  const history = useHistory();

  const formFields = [
    {
      name: "email",
      placeholder: "Enter your email",
      type: "email",
      initialValues: "",
      required: true,
      validation: Yup.string()
        .email("Invalid email address")
        .required("Required field"),
    },
    {
      name: "password",
      placeholder: "Enter your new password",
      type: "password",
      initialValues: "",
      required: true,
      validation: Yup.string()
        .required("Required field")
        .min(6, "Password must be at least 6 characters"),
    },
    {
      name: "password_confirmation",
      placeholder: "Confirm your password",
      type: "password",
      initialValues: "",
      required: true,
      validation: Yup.string()
        .required("Required field")
        .min(6, "Password must be at least 6 characters")
        .oneOf([Yup.ref("password"), null], "Password should match"),
    },
  ];

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      token: query.get("token"),
    };
    const { data, error } = await resetPassword(payload);

    if (!error && data) {
      Toast.success("Your password has been successfully reset");

      return history.pushState(null, "/auth/login");
    }

    return Toast.error(error.errors.join(" "));
  };

  return (
    <div className="container p-10 flex justify-center items-center">
      <div className="w-1/2 sm:w-full">
        <h2 className="font-semibold text-center text-xl">
          Change your password
        </h2>

        <Form
          isEditable={true}
          initialValues={getInitialValues(formFields)}
          Schema={getSchema(formFields)}
          fields={formFields}
          onSubmit={handleSubmit}
          buttonTitle={"Submit"}
        />
      </div>
    </div>
  );
}
