import React from "react";

const Notice = ({ message }) => {
  return (
    <div className="bg-notice-primary w-1/2 sm:w-full p-4 flex flex-row items-center rounded-md">
      <ion-icon
        name="alert-circle-outline"
        style={{ color: "#0c5460", fontSize: 20, fontWeight: "bold" }}
      ></ion-icon>
      <label className="ml-5 text-notice-text font-semibold">{message}</label>
    </div>
  );
};

export default Notice;
