import React, { useState, useEffect } from "react";

import {
  VeganIcon,
  VeryHotIcon,
  GlutanFreeIcon,
  SlightlySpicyIcon,
} from "components/Icons";
import { getTags } from "helper/tag.helper";
import useResize from "hooks/useResize";

const Order = ({ menus, onAddOrderItem }) => {
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [items, setItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);

  const [categoryValue, setCategoryValue] = useState(null); // used for matching the category value when resize to `sm`
  const [open, setOpen] = useState(false);

  const { isSm } = useResize();

  useEffect(() => {
    const results = menus[0]?.items?.[0]?.items;
    setOriginalItems(results);
    setItems(results);
  }, [menus]);

  const handleSearch = (input) => {
    if (!input) {
      setItems(originalItems);
    }
    setItems(
      originalItems.filter((item) =>
        item.name.toLowerCase().includes(input.toLowerCase())
      )
    );
  };

  return (
    <>
      <div className="body">
        <div className="grid grid-cols-3 mb-5 sm:grid-cols-1">
          <div className="grid col-span-1 sm:fixed top-0 left-0 right-0">
            {isSm ? (
              <select
                id="categories"
                name="categories"
                value={categoryValue}
                onChange={(e) => {
                  setCategoryValue(e.target.value);
                  const selectedCategory = JSON.parse(e.target.value);
                  setCategory(selectedCategory["sub-category-name"]);
                  setItems(selectedCategory.items);
                  setOriginalItems(selectedCategory.items);
                }}
                className="bg-white py-3 px-3 m-5 rounded placeholder-gray-darkest font-semibold filter drop-shadow font-popin outline-none sm:text-sm border border-green-primary border-opacity-40"
              >
                {menus.map((menu, ind) => (
                  <optgroup label={menu.name} key={ind}>
                    {menu.items.map((item, index) => (
                      <option value={JSON.stringify(item)} key={index}>
                        {item["sub-category-name"]}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            ) : (
              <div
                style={{ maxHeight: `60vh` }}
                className="flex flex-col p-4 overflow-y-scroll font-popin"
              >
                {menus.map((item, index) => (
                  <span
                    key={index}
                    onClick={() => {
                      setOpen(!open);
                      setCategory(item.name);
                    }}
                  >
                    <label
                      className={`leading-10 cursor-pointer hover:text-rose-500 text-sm font-semibold ${
                        item.name === category ? "text-red-light" : "text-black"
                      }`}
                    >
                      {item.name}
                    </label>
                    <span className="ml-4 flex flex-col">
                      {item.items.map((subItem, subIndex) => (
                        <span
                          key={subIndex}
                          onClick={() => {
                            setSubCategory(subItem["sub-category-name"]);
                            setItems(subItem.items);
                            setOriginalItems(subItem.items);
                          }}
                        >
                          <label
                            className={`text-sm leading-10 cursor-pointer hover:text-rose-500 ${
                              subItem["sub-category-name"] === subCategory
                                ? "text-red-light"
                                : "text-black"
                            }`}
                          >
                            {subItem["sub-category-name"]}
                          </label>
                        </span>
                      ))}
                    </span>
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className="grid col-span-2 sm:col-span-1 sm:mt-12">
            <input
              type="text"
              placeholder="Search food items"
              onChange={(e) => handleSearch(e.target.value)}
              className="bg-white py-3 px-3 m-5 sm:m-0 sm:mt-4  rounded placeholder-gray-darkest font-semibold filter drop-shadow font-popin sm:text-sm w-full border border-green-primary border-opacity-40 focus:outline-none"
            />
            <div
              className="flex flex-col ml-2 overflow-y-scroll sm:mt-3 sm:ml-0"
              style={{ height: "60vh" }}
            >
              {items?.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-row justify-between mt-2 p-5 box-border rounded ${
                    (index + 1) % 2 === 0 ? "bg-gray-lightest" : ""
                  }`}
                >
                  <div className="inline-grid">
                    <span className="flex flex-row items-center">
                      <label className="font-popin font-semibold sm:text-sm">
                        {item.name}
                      </label>
                      {!isSm && (
                        <span className="flex flex-row justify-evenly flex-wrap">
                          {getTags(JSON.parse(item?.tags)).map((tag) =>
                            tag.icon()
                          )}
                        </span>
                      )}
                    </span>
                    {!isSm && (
                      <label
                        htmlFor="description"
                        className="font-popin font-regular text-sm w-full"
                      >
                        {item.description}
                      </label>
                    )}
                  </div>

                  <span className="inline-grid">
                    <span className="flex flex-row items-center">
                      {!isSm && (
                        <label>
                          {process.env.REACT_APP_CURRENCY}
                          {item.price}
                        </label>
                      )}
                      <button
                        className="btn-green w-20 ml-10"
                        onClick={() => onAddOrderItem(item)}
                      >
                        Add
                      </button>
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {!isSm && (
        <div className="border-2 border-gray-lightest grid grid-cols-4 gap-3 rounded-md p-2 mx-20 sm:w-full sm:ml-0">
          <div className="flex flex-row items-center justify-center">
            <VeganIcon />
            <span className="text-red-light text-sm">Vegetarian</span>
          </div>

          <div className="flex flex-row items-center justify-center">
            <SlightlySpicyIcon />
            <span className="text-red-light text-sm">Slightly spiced</span>
          </div>
          <div className="flex flex-row items-center justify-center">
            <VeryHotIcon />
            <span className="text-red-light text-sm">Very Hot</span>
          </div>
          <div className="flex flex-row items-center justify-center">
            <GlutanFreeIcon />
            <span className="text-red-light text-sm">Glutan Free</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Order;
