import thunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducer";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { applyMiddleware, createStore } from "redux";

export const middleware = [thunk, logger];

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

export const preducer = persistReducer(persistConfig, reducers);

export const store = createStore(preducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);
