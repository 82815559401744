import React from "react";

export const EditIcon = ({ onClick }) => {
  return (
    <span onClick={onClick} className="cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
      >
        <g id="create-24px" transform="translate(0)">
          <path
            id="Path_120"
            data-name="Path 120"
            d="M0,0H14V14H0Z"
            transform="translate(0)"
            fill="none"
          />
          <path
            id="Path_121"
            data-name="Path 121"
            d="M3,9.925v1.822H4.823L10.2,6.373,8.376,4.55Zm1.419.85H3.972v-.447l4.4-4.4.447.447Zm7.188-6.5L10.47,3.141a.487.487,0,0,0-.685,0L8.9,4.03l1.823,1.822.889-.889a.484.484,0,0,0,0-.685Z"
            transform="translate(-0.374 -0.374)"
            fill="#fff"
          />
        </g>
      </svg>
    </span>
  );
};

export const CloseIcon = ({ onClick, color, size }) => {
  return (
    <span onClick={onClick} className="cursor-pointer">
      <svg
        id="clear-24px"
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size : "14"}
        height={size ? size : "14"}
        viewBox="0 0 14 14"
      >
        <path
          id="Path_204"
          data-name="Path 204"
          d="M0,0H14V14H0Z"
          fill="none"
        />
        <path
          id="Path_205"
          data-name="Path 205"
          d="M13.092,5.815,12.277,5,9.046,8.231,5.815,5,5,5.815,8.231,9.046,5,12.277l.815.815L9.046,9.861l3.231,3.231.815-.815L9.861,9.046Z"
          transform="translate(-2.046 -2.046)"
          fill={color ? color : "#fff"}
        />
      </svg>
    </span>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 20 20"
    >
      <path
        id="plus"
        d="M5.953,0a5.953,5.953,0,1,0,5.953,5.953A5.96,5.96,0,0,0,5.953,0Zm2.6,6.449H6.449V8.558a.5.5,0,0,1-.992,0V6.449H3.349a.5.5,0,0,1,0-.992H5.457V3.349a.5.5,0,0,1,.992,0V5.457H8.558a.5.5,0,0,1,0,.992Zm0,0"
        fill="#313a4b"
      />
    </svg>
  );
};

export const LocationIcon = () => {
  return (
    <svg
      id="location_icon"
      data-name="location icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_64" data-name="Path 64" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M12,12a2,2,0,1,1,2-2A2.006,2.006,0,0,1,12,12Zm6-1.8a6,6,0,1,0-12,0c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2ZM12,2a7.955,7.955,0,0,1,8,8.2q0,4.98-8,11.8Q4.005,15.175,4,10.2A7.955,7.955,0,0,1,12,2Z"
        fill="#2a7a83"
      />
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg
      id="profile_icon"
      data-name="profile icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 15 15"
    >
      <path id="Path_195" data-name="Path 195" d="M0,0H15V15H0Z" fill="none" />
      <path
        id="Path_196"
        data-name="Path 196"
        d="M9.576,5.394A1.394,1.394,0,1,1,8.182,6.788,1.4,1.4,0,0,1,9.576,5.394m0,6.273c1.882,0,4.043.9,4.182,1.394v.7H5.394v-.69c.139-.5,2.3-1.4,4.182-1.4M9.576,4a2.788,2.788,0,1,0,2.788,2.788A2.787,2.787,0,0,0,9.576,4Zm0,6.273C7.715,10.273,4,11.208,4,13.062v2.091H15.153V13.062C15.153,11.208,11.438,10.273,9.576,10.273Z"
        transform="translate(-2.153 -2.153)"
        fill="#313a4b"
      />
    </svg>
  );
};

export const OrderIcon = () => {
  return (
    <svg
      id="history_icon"
      data-name="history icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 12 12"
    >
      <path id="Path_197" data-name="Path 197" d="M0,0H12V12H0Z" fill="none" />
      <path
        id="Path_198"
        data-name="Path 198"
        d="M10.785,2.011H10.3V1H9.325V2.011H5.433V1H4.46V2.011H3.973A1,1,0,0,0,3,3.021v7.074a1,1,0,0,0,.973,1.011h6.812a1,1,0,0,0,.973-1.011V3.021A1,1,0,0,0,10.785,2.011Zm0,8.084H3.973V5.042h6.812ZM3.973,4.032V3.021h6.812V4.032Zm.973,2.021H9.812V7.063H4.946Zm0,2.021H8.352V9.084H4.946Z"
        transform="translate(-1.105 -1)"
        fill="#313a4b"
      />
    </svg>
  );
};

export const AddressIcon = () => {
  return (
    <svg
      id="address_icon"
      data-name="address icon"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 13.419 13.419"
    >
      <path
        id="Path_216"
        data-name="Path 216"
        d="M0,0H13.419V13.419H0Z"
        fill="none"
      />
      <path
        id="Path_217"
        data-name="Path 217"
        d="M7.591,4.5l2.8,2.516v4.367H9.269V8.032H5.914v3.355H4.8V7.02L7.591,4.5m0-1.5L2,8.032H3.677v4.473H7.032V9.15H8.15v3.355h3.355V8.032h1.677Z"
        transform="translate(-0.882 -1.323)"
        fill="#313a4b"
      />
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg
      id="logout_icon"
      data-name="logout icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 12 12"
    >
      <rect
        id="Rectangle_440"
        data-name="Rectangle 440"
        width="20"
        height="20"
        fill="none"
      />
      <path
        id="Path_199"
        data-name="Path 199"
        d="M9.575,3.622V7.906l1.049,1.049V2.049A1.047,1.047,0,0,0,9.575,1.005L4.332,1a1.061,1.061,0,0,0-.981.682l1.94,1.94ZM4.332,2.049H9.575v.524H4.332Zm7.441,9.538-1.148-1.148L9.575,9.39,4.332,4.146,3.283,3.1,2.135,1.949l-.745.739L3.283,4.581v6.906a1.052,1.052,0,0,0,1.049,1.049H9.575a1.061,1.061,0,0,0,.981-.682l.477.477Zm-2.2-.1H4.332v-.524H9.575ZM4.332,9.914V5.63L8.616,9.914Z"
        transform="translate(-0.695 -1)"
        fill="#313a4b"
      />
    </svg>
  );
};

export const VeganIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 33 33"
    >
      <defs>
        <filter
          id="Ellipse_1"
          x="0"
          y="0"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_14" data-name="Group 14" transform="translate(9.077 0.571)">
        <g
          transform="matrix(1, 0, 0, 1, -9.08, -0.57)"
          filter="url(#Ellipse_1)"
        >
          <circle
            id="Ellipse_1-2"
            data-name="Ellipse 1"
            cx="7.5"
            cy="7.5"
            r="7.5"
            transform="translate(9 6)"
            fill="#707070"
          />
        </g>
        <text
          id="v"
          transform="translate(2.923 17.429)"
          fill="#b6e9b1"
          fontSize="15"
          fontFamily="Helvetica"
        >
          <tspan x="0" y="0">
            v
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export const SlightlySpicyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 34 34"
    >
      <defs>
        <filter
          id="Ellipse_1"
          x="0"
          y="0"
          width="34"
          height="34"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_15" data-name="Group 15" transform="translate(9.07 5.777)">
        <g
          transform="matrix(1, 0, 0, 1, -9.07, -5.78)"
          filter="url(#Ellipse_1)"
        >
          <circle
            id="Ellipse_1-2"
            data-name="Ellipse 1"
            cx="8"
            cy="8"
            r="8"
            transform="translate(9 6)"
            fill="#fff"
          />
        </g>
        <g id="chili" transform="translate(4.119 5.047)">
          <path
            id="Path_7"
            data-name="Path 7"
            d="M42.144,1.032A.13.13,0,0,1,42.05.811a.514.514,0,0,0,.183-.4c-.015-.066-.075-.119-.177-.156a.13.13,0,1,1,.089-.245.485.485,0,0,1,.342.342.742.742,0,0,1-.249.641A.13.13,0,0,1,42.144,1.032Z"
            transform="translate(-36.556 -0.001)"
            fill="#659c35"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M4.55,7.908c-.018.061-.035.125-.052.2-.456,2.008-2.133,3.068-4.023,4.115-.086.048-.1.2-.013.183,1.218-.183,4.4.126,5.953-3.519,0,0,.8-1.675-.223-1.951A1.1,1.1,0,0,0,4.55,7.908Z"
            transform="translate(-0.403 -5.915)"
            fill="#d13834"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M34.163,7.839c.116-.526.112-1.155-.5-1.32a1.159,1.159,0,0,0-1.718.823c.324-.025.759-.09.881-.264a.689.689,0,0,1,.14.7s.611-.07.558-.558a.932.932,0,0,1,.279.837A1.072,1.072,0,0,0,34.163,7.839Z"
            transform="translate(-27.836 -5.546)"
            fill="#88c057"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M14.058,24.468a.13.13,0,0,1-.04-.254c2.821-.909,3.132-2.839,3.135-2.858a.13.13,0,0,1,.258.037c-.013.086-.339,2.11-3.313,3.069A.137.137,0,0,1,14.058,24.468Z"
            transform="translate(-12.166 -18.477)"
            fill="#f75b57"
          />
        </g>
      </g>
    </svg>
  );
};

export const VeryHotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 34 34"
    >
      <defs>
        <filter
          id="Ellipse_1"
          x="0"
          y="0"
          width="34"
          height="34"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_424"
        data-name="Group 424"
        transform="translate(8.887 5.777)"
      >
        <g
          transform="matrix(1, 0, 0, 1, -8.89, -5.78)"
          filter="url(#Ellipse_1)"
        >
          <circle
            id="Ellipse_1-2"
            data-name="Ellipse 1"
            cx="8"
            cy="8"
            r="8"
            transform="translate(9 6)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_16"
          data-name="Group 16"
          transform="translate(3.076 5.047)"
        >
          <g id="chili">
            <path
              id="Path_7"
              data-name="Path 7"
              d="M42.127.932a.118.118,0,0,1-.085-.2A.464.464,0,0,0,42.208.37c-.014-.06-.068-.107-.16-.141a.118.118,0,1,1,.081-.221.438.438,0,0,1,.308.309A.67.67,0,0,1,42.212.9.118.118,0,0,1,42.127.932Z"
              transform="translate(-37.082 -0.001)"
              fill="#659c35"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M4.147,7.8c-.016.055-.032.113-.047.179C3.689,9.793,2.175,10.75.468,11.7c-.078.043-.089.177-.012.165,1.1-.165,3.971.113,5.375-3.177,0,0,.725-1.512-.2-1.762A.99.99,0,0,0,4.147,7.8Z"
              transform="translate(-0.403 -6.001)"
              fill="#d13834"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M33.948,7.7c.1-.475.1-1.043-.451-1.192a1.047,1.047,0,0,0-1.551.743c.293-.022.685-.081.8-.239a.622.622,0,0,1,.126.63s.551-.063.5-.5a.841.841,0,0,1,.252.756A.967.967,0,0,0,33.948,7.7Z"
              transform="translate(-28.236 -5.626)"
              fill="#88c057"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M14.045,24.155a.118.118,0,0,1-.036-.23c2.547-.821,2.828-2.563,2.83-2.58a.118.118,0,0,1,.233.034c-.011.077-.306,1.905-2.991,2.771A.124.124,0,0,1,14.045,24.155Z"
              transform="translate(-12.337 -18.746)"
              fill="#f75b57"
            />
          </g>
          <g id="chili-2" data-name="chili" transform="translate(3.121)">
            <path
              id="Path_7-2"
              data-name="Path 7"
              d="M42.127.932a.118.118,0,0,1-.085-.2A.464.464,0,0,0,42.208.37c-.014-.06-.068-.107-.16-.141a.118.118,0,1,1,.081-.221.438.438,0,0,1,.308.309A.67.67,0,0,1,42.212.9.118.118,0,0,1,42.127.932Z"
              transform="translate(-37.082 -0.001)"
              fill="#659c35"
            />
            <path
              id="Path_8-2"
              data-name="Path 8"
              d="M4.147,7.8c-.016.055-.032.113-.047.179C3.689,9.793,2.175,10.75.468,11.7c-.078.043-.089.177-.012.165,1.1-.165,3.971.113,5.375-3.177,0,0,.725-1.512-.2-1.762A.99.99,0,0,0,4.147,7.8Z"
              transform="translate(-0.403 -6.001)"
              fill="#d13834"
            />
            <path
              id="Path_9-2"
              data-name="Path 9"
              d="M33.948,7.7c.1-.475.1-1.043-.451-1.192a1.047,1.047,0,0,0-1.551.743c.293-.022.685-.081.8-.239a.622.622,0,0,1,.126.63s.551-.063.5-.5a.841.841,0,0,1,.252.756A.967.967,0,0,0,33.948,7.7Z"
              transform="translate(-28.236 -5.626)"
              fill="#88c057"
            />
            <path
              id="Path_10-2"
              data-name="Path 10"
              d="M14.045,24.155a.118.118,0,0,1-.036-.23c2.547-.821,2.828-2.563,2.83-2.58a.118.118,0,0,1,.233.034c-.011.077-.306,1.905-2.991,2.771A.124.124,0,0,1,14.045,24.155Z"
              transform="translate(-12.337 -18.746)"
              fill="#f75b57"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const GlutanFreeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 34 34"
    >
      <defs>
        <filter
          id="Ellipse_3"
          x="0"
          y="0"
          width="34"
          height="34"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_18" data-name="Group 18" transform="translate(8.926 5.777)">
        <g
          transform="matrix(1, 0, 0, 1, -8.93, -5.78)"
          filter="url(#Ellipse_3)"
        >
          <circle
            id="Ellipse_3-2"
            data-name="Ellipse 3"
            cx="8"
            cy="8"
            r="8"
            transform="translate(9 6)"
            fill="#fff"
          />
        </g>
        <g id="gluten-free" transform="translate(4.206 4.206)">
          <g id="Group_17" data-name="Group 17" transform="translate(0)">
            <path
              id="Path_14"
              data-name="Path 14"
              d="M8.289,7.941,5.577,5.229A2.446,2.446,0,0,0,6.331,4.9a.661.661,0,0,0,.27-.779,2.294,2.294,0,0,0,.862-.354.661.661,0,0,0,0-1.117,2.978,2.978,0,0,0-.411-.22,1.36,1.36,0,0,0,.235-.185,2.039,2.039,0,0,0,.487-1.009.661.661,0,0,0-.79-.79A2.039,2.039,0,0,0,5.975.928a1.358,1.358,0,0,0-.185.234,2.98,2.98,0,0,0-.219-.41.661.661,0,0,0-1.117,0,2.294,2.294,0,0,0-.354.862.661.661,0,0,0-.779.27,2.447,2.447,0,0,0-.334.754L.42.072A.246.246,0,0,0,.072.42L2.44,2.787a.66.66,0,0,0-.255.234,2.039,2.039,0,0,0-.369,1.058,2.522,2.522,0,0,0,.6,1.378L.57,7.3a.246.246,0,1,0,.348.348L2.758,5.8a2.523,2.523,0,0,0,1.379.6,2.039,2.039,0,0,0,1.058-.369.659.659,0,0,0,.234-.255L7.941,8.289a.246.246,0,1,0,.348-.348ZM6.323,1.276A1.6,1.6,0,0,1,7.092.921a.176.176,0,0,1,.039,0,.17.17,0,0,1,.163.207,1.6,1.6,0,0,1-.355.769,1.965,1.965,0,0,1-1.018.4A1.965,1.965,0,0,1,6.323,1.276Zm.083,1.492a1.6,1.6,0,0,1,.8.293.169.169,0,0,1,0,.286,1.6,1.6,0,0,1-.8.293,1.965,1.965,0,0,1-1-.436A1.965,1.965,0,0,1,6.405,2.768ZM4.869,1.015a.169.169,0,0,1,.286,0,1.6,1.6,0,0,1,.293.8,1.965,1.965,0,0,1-.436,1,1.965,1.965,0,0,1-.436-1A1.6,1.6,0,0,1,4.869,1.015ZM3.737,2.148a.169.169,0,0,1,.286,0,1.6,1.6,0,0,1,.293.8,1.586,1.586,0,0,1-.269.756l-.585-.585a.86.86,0,0,1-.019-.171A1.6,1.6,0,0,1,3.737,2.148ZM2.743,5.083a1.965,1.965,0,0,1-.436-1,1.6,1.6,0,0,1,.293-.8.169.169,0,0,1,.286,0,1.6,1.6,0,0,1,.293.8A1.965,1.965,0,0,1,2.743,5.083Zm2.189.533a1.6,1.6,0,0,1-.8.293,1.965,1.965,0,0,1-1-.436,1.965,1.965,0,0,1,1-.436,1.6,1.6,0,0,1,.8.293.169.169,0,0,1,0,.286Zm.341-.843A.861.861,0,0,1,5.1,4.754l-.585-.585A1.586,1.586,0,0,1,5.273,3.9a1.6,1.6,0,0,1,.8.293.169.169,0,0,1,0,.286A1.6,1.6,0,0,1,5.273,4.772Z"
              transform="translate(0 0)"
              fill="#7e3636"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
