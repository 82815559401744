import * as Yup from "yup";
import Form from "components/Form";
import React, { useState, useRef } from "react";
import { checkCaptcha, register } from "api/request";
import { setUser } from "user/user.action";
import { useDispatch } from "react-redux";
import { maxPhoneLength } from "app/app.constant";
import { getSchema, getInitialValues } from "helper/form.helper";
import Toast from "components/Toast";

const formFields = [
  {
    name: "fname",
    placeholder: "First Name",
    type: "text",
    initialValues: "",
    required: true,
    validation: Yup.string().required("Required field"),
  },
  {
    name: "lname",
    placeholder: "Last Name",
    type: "text",
    initialValues: "",
    required: true,
    validation: Yup.string().required("Required field"),
  },
  {
    name: "phone",
    placeholder: "Phone Number",
    type: "phone",
    initialValues: "",
    required: true,
    validation: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .min(
        maxPhoneLength,
        `Phone number must be exactly ${maxPhoneLength} digits`
      )
      .max(
        maxPhoneLength,
        `Phone number must be exactly ${maxPhoneLength} digits`
      ),
  },
  {
    name: "street_address",
    placeholder: "Street address",
    type: "text",
    initialValues: "",
    required: true,
    validation: Yup.string().required("Required field"),
  },
  {
    name: "postal_code",
    placeholder: "Postal code",
    type: "text",
    initialValues: "",
    required: true,
    validation: Yup.string().required("Required field"),
  },
  {
    name: "city",
    placeholder: "City",
    type: "text",
    initialValues: "",
    required: true,
    validation: Yup.string().required("Required field"),
  },
  {
    name: "email",
    placeholder: "Email",
    type: "email",
    initialValues: "",
    required: true,
    validation: Yup.string()
      .email("Invalid email address")
      .required("Required field"),
  },
  {
    name: "password",
    type: "password",
    placeholder: "Password",
    initialValues: "",
    required: true,
    validation: Yup.string()
      .required("Required field")
      .min(6, "Password must be at least 6 characters"),
  },
];

export default function Register({ onSuccess, isHalfSize }) {
  const dispatch = useDispatch();
  const captchaRef = useRef(null);
  const [fields, setFields] = useState(formFields);
  const [captchEmpty, setCaptchaEmpty] = useState(false);
  const [token, setToken] = useState(false);

  const handleSubmit = async (values) => {
    if (!token) {
      setCaptchaEmpty(true);
      return Toast.error("Please verify the captcha & try again!");
    }

    const { data, error } = await checkCaptcha(token);

    if (!error && data) {
      handleRegister(values);
    }
  };

  const resetCaptcha = () => {
    captchaRef.current.resetCaptcha();
    setToken("");
  };

  const handleRegister = async (values) => {
    const { data, error } = await register(values);

    if (data.error) {
      const message = Object.values(data.error).join(" ");
      return Toast.error(message);
    }

    if (!error && data) {
      dispatch(setUser(data));
      localStorage.setItem("token", data.token);
      setFields(formFields);
      resetCaptcha();
      return onSuccess?.();
    }

    return Toast.error("Something went wrong, please try again!");
  };

  return (
    <>
      <div className="flex justify-center items-center mt-7">
        <div className={`${isHalfSize ? "w-1/2" : "w-full"} sm:w-full`}>
          <h2 className="font-semibold text-center text-xl text-red-light">
            Donot have account?
          </h2>

          <Form
            isEditable={true}
            hasCaptcha={true}
            initialValues={getInitialValues(fields)}
            Schema={getSchema(fields)}
            fields={fields}
            loading={true}
            captchEmpty={captchEmpty}
            captchaRef={captchaRef}
            onVerifyCaptcha={(token, ekey) => {
              setToken(token);
              setCaptchaEmpty(false);
            }}
            onExpireCaptcha={() => setToken("")}
            onSubmit={handleSubmit}
            buttonTitle={"Register"}
          />
        </div>
      </div>
    </>
  );
}

Register.defaultProps = {
  isHalfSize: true,
};
