import "./App.css";
import {
  Link,
  Route,
  Switch,
  Redirect,
  useHistory,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Auth from "pages/Auth";
import Order from "pages/Main";
import Login from "pages/Login";
import Profile from "pages/Profile";
import Register from "pages/Register";
import ResetPassword from "pages/ResetPassword";
import ForgetPassword from "pages/Forgotpassword";

import Alert from "components/Alert";
import { LocationIcon } from "components/Icons";

import { isEmpty } from "helper/form.helper";
import { setBranch } from "order/order.action";
import { setLogout, setUser } from "user/user.action";
import { getBranches, getCustomerDetail } from "./api/request";

const Menu = ({ open, setOpen, onLogout, name }) => {
  return (
    <div className="relative">
      <button
        id="dropdownDividerButton"
        data-dropdown-toggle="dropdownDivider"
        className="outline-none text-black bg-blue-700  font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:underline"
        type="button"
        onClick={() => setOpen(!open)}
      >
        {name}
        <svg
          className="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      <div
        id="dropdownDivider"
        onMouseLeave={() => setOpen(false)}
        className={`${
          open ? "block" : "hidden"
        } z-50 w-44 text-base  absolute right-0 top-12 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600`}
      >
        <ul className="py-1 mt-5 z-30" aria-labelledby="dropdownDividerButton">
          <li>
            <Link
              to="/profile"
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer hover:text-rose-500"
            >
              Profile
            </Link>
          </li>
          <li>
            <Link
              to="/profile/orderHistory"
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer hover:text-rose-500"
            >
              Order History
            </Link>
          </li>
          <li>
            <Link
              to="/profile/address"
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer hover:text-rose-500"
            >
              Address
            </Link>
          </li>
          <li>
            <a
              onClick={onLogout}
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer hover:text-rose-500"
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const Header = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const order = useSelector((state) => state.order);
  const user = useSelector((state) => state.user);
  const isAuthenticated = !isEmpty(user) && !!localStorage.getItem("token");

  const onLogout = () => {
    dispatch(setLogout());
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  const getFullName = (fname, lname) => {
    if (!fname || !lname) {
      return "";
    }

    return <span className="capitalize">{fname + " " + lname}</span>;
  };

  const getAddress = () => {
    const parsedAdress = !isEmpty(order.branch.address)
      ? JSON.parse(order?.branch?.address)[0]
      : {};
    const streetAddress = parsedAdress?.street_address;
    const postalCode = parsedAdress?.postal_code;
    const phoneNumber = order?.branch.phone;

    return !isEmpty(parsedAdress)
      ? `${streetAddress} , ${postalCode} / ${phoneNumber}`
      : "";
  };

  const getBranchName = () => {
    return !isEmpty(order.branch.name) ? order.branch.name.toUpperCase() : "";
  };

  return (
    <div className="flex flex-row justify-between px-10 sm:px-0 py-5 items-center bg-white shadow-md h-20 filter drop-shadow">
      <div className="flex flex-row items-center">
        <Link to="/">
          <img
            alt={"logo-header"}
            src={process.env.PUBLIC_URL + "/logo-alotama.png"}
            className="h-25 w-20 object-contain"
          />
        </Link>

        {!isEmpty(order?.branch?.slots) && (
          <span className="font-popin text-lg">
            <label className="">{getBranchName()}</label>
            <span className="flex flex-row items-center">
              <LocationIcon />
              <label className="text-sm ml-2 font-popin font-normal text-gray-darkest sm:text-xs">
                {getAddress()}
              </label>
            </span>
          </span>
        )}
      </div>

      <span className="flex flex-row items-center">
        <span className="text-black font-popin text-sm ml-2 cursor-pointer">
          {isAuthenticated ? (
            <Menu
              open={open}
              setOpen={setOpen}
              onLogout={onLogout}
              name={getFullName(user.fname, user.lname)}
              className="capitalize"
            />
          ) : (
            <span className="flex flex-row justify-around w-44">
              <label
                className="cursor-pointer hover:underline"
                onClick={() => history.replace("/auth/login")}
              >
                Login
              </label>
              <label
                className="cursor-pointer hover:underline"
                onClick={() => history.replace("/register")}
              >
                Register
              </label>
            </span>
          )}
        </span>
      </span>
    </div>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);

  const history = useHistory();
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState(null);
  const [branchAlert, setBranchAlert] = useState(false);

  const fetchBranches = async () => {
    const { data, error } = await getBranches();

    if (!error) {
      setBranches(data.message);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    setBranchId(order?.branch?.id);
  }, [order?.branch?.id]);

  const handleSubmit = () => {
    if (!!branchId && branchId !== "0") {
      const branchInfo = branches.find((branch) => branch.id == branchId);
      dispatch(setBranch(branchInfo));
      return history.push("/order");
    }

    return setBranchAlert(true);
  };

  return (
    <div className="relative h-full max-height-screen overflow-hidden sm:bg-transparent">
      <div className="absolute w-full h-full max-h-full opacity-70 bg-black sm:block z-minus1">
        <img
          src={process.env.PUBLIC_URL + "/background.jpg"}
          className="h-full w-full object-cover"
          alt="bg"
        />
      </div>
      <span className="absolute top-0 self-start extra-left sm:left-10 sm:hidden md:hidden">
        <img
          src="./plate.png"
          className="w-100 h-100 md:hidden sm:hidden"
          alt="plate"
        />
      </span>
      <span className="absolute right-0 super-right bottom-down">
        <img
          src="./plates.png"
          className="w-100 h-100 sm:hidden"
          alt="plates"
        />
      </span>
      <div className="h-500 flex flex-col justify-center items-center mt-40 sm:mt-0 sm:px-5">
        <h3 className="font-regular text-3xl sm:text-2xl text-center drop-shadow-2xl pt-10 text-black z-10 sm:text-gray-darkest sm:font-popins sm:font-light">
          Order online at Alootama
        </h3>
        <div className="bg-gray-darkest h-40 sm:h-60 lg:p-10 md:p-10 w-1/3 md:w-1/2 sm:w-full lg:w-5/12 rounded-md mt-10 flex flex-col items-center justify-center z-20">
          <div className="text-white text-sm text-left pb-5">
            Choose your local store
          </div>
          <div className="flex">
            <span className="flex flex-col">
              <select
                id="branches"
                name="branches"
                value={branchId ? branchId : ""}
                onChange={(e) => setBranchId(e.target.value)}
                className="bg-white rounded-sm mt-2 py-2 px-5 outline-none"
              >
                <option value="0">Select Store</option>
                {branches.map((branch, index) => (
                  <option value={branch.id} key={index}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </span>
            <span className="flex items-end justify-end ml-5 w-32">
              <button
                className="btn-green w-32 align-bottom p-2 sm:w-full sm:mt-2"
                onClick={handleSubmit}
              >
                Go
              </button>
            </span>
          </div>
        </div>
      </div>
      <Alert
        open={branchAlert}
        message={"Please select the branch first."}
        hasCancel={false}
        onCancel={() => setBranchAlert(false)}
      />
    </div>
  );
};

function getModalStyle() {
  const right = 0;
  const top = 15;

  return {
    width: 400,
    zIndex: 500,
    top: `${top}%`,
    right: `${right}%`,
    position: "absolute",
    padding: "20px 30px",
    backgroundColor: "white",
    transform: `translate(-${top}%, -${right}%)`,
  };
}

const Modal = ({ hideModal }) => {
  const [selectedTab, setSelectedTab] = React.useState("login");

  return (
    <div style={getModalStyle()} className="rounded-sm">
      <span className="flex flex-row justify-evenly">
        <label
          className={
            selectedTab === "login"
              ? "text-red-dark"
              : "hover:text-blue cursor-pointer"
          }
          onClick={() => setSelectedTab("login")}
        >
          Login
        </label>
        <label
          className={
            selectedTab === "register"
              ? "text-red-dark"
              : "hover:text-blue cursor-pointer"
          }
          onClick={() => setSelectedTab("register")}
        >
          Register
        </label>
      </span>

      {selectedTab === "login" ? (
        <Login hideModal={hideModal} />
      ) : (
        <Register hideModal={hideModal} />
      )}
    </div>
  );
};

const NoPage = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={process.env.PUBLIC_URL + "/404.png"} alt="404" />
      <label className="font-sans text-2xl">
        The page you requested is not found
      </label>
    </div>
  );
};

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  // this route guards specific pages against unauthenticated users
  const user = useSelector((state) => state.user);
  const isAuthenticated = localStorage.getItem("token") && !isEmpty(user);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Footer = () => {
  return (
    <span className="text-center font-sans p-4 text-xs text-warmGray-500 bg-transparent ">
      Copyright © Alootama . All Rights Reserved Supported and Maintained by
      <a href="https://www.algorithm.com.np" target="_blank" rel="noreferrer">
        <span className="hover:underline"> Algorithm Pvt Ltd</span>
      </a>
    </span>
  );
};

function App() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const isAuthenticated = localStorage.getItem("token");

  const [isModalVisible, setShowModal] = React.useState(false);
  const [footer, setFooter] = React.useState(false);

  const bodyClass = `max-height-screen bg-gray-lighest h-full ${
    isModalVisible ? "bg-gray-dark" : ""
  } ${open ? "z-minus1" : "z-0"}`;

  const fetchUser = async () => {
    const { data, error } = await getCustomerDetail();
    if (!error && data) {
      dispatch(setUser(data.message));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated]);

  return (
    <Router>
      <div className="flex flex-col h-full">
        <Header
          showModal={() => setShowModal(true)}
          open={open}
          setOpen={setOpen}
        />
        {isModalVisible && <Modal hideModal={() => setShowModal(false)} />}

        <div className={bodyClass}>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/auth/login" component={Auth} exact />
            <Route path="/auth/" component={Auth} exact />
            <Route path="/order" component={Order} exact />
            <Route path="/register" component={Register} exact />
            <Route path="/forgetpassword" component={ForgetPassword} exact />
            <Route path="/reset-password" component={ResetPassword} exact />
            <AuthenticatedRoute path="/profile" component={Profile} exact />
            <AuthenticatedRoute
              path="/profile/address"
              component={Profile}
              exact
            />
            <AuthenticatedRoute
              path="/profile/account"
              component={Profile}
              exact
            />
            <AuthenticatedRoute
              path="/profile/orderHistory"
              component={Profile}
              exact
            />

            <Route path="*" component={NoPage} />
          </Switch>
        </div>

        <ToastContainer
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          pauseOnHover={false}
        />

        <Footer />
      </div>
    </Router>
  );
}

export default App;
