import { SET_LOGOUT } from "user/user.type";
import { SET_BRANCH, SET_ITEMS } from "./order.type";

const initialState = {
  branch: {
    slots: [],
  },
  orders: [],
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BRANCH:
      return {
        ...state,
        branch: action.payload,
      };

    case SET_LOGOUT:
      return initialState;

    case SET_ITEMS:
      return {
        ...state,
        orders: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
