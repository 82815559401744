import moment from "moment";
import * as Yup from "yup";

export const getInitialValues = (fields) => {
  let obj = {};

  fields.forEach((field) => {
    obj[field.name] = field.initialValue;
  });

  return obj;
};

export const getSchema = (fields) => {
  let obj = {};

  fields.forEach((field) => {
    obj[field.name] = field.validation;
  });

  return Yup.object().shape(obj);
};

export const isEmpty = (value) => {
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const getMaxMinTime = (slots) => {
  const day = moment().format("dddd").toLowerCase();

  const filteredSlots = slots.filter((slot) => slot.day === day);

  const minTime = moment(filteredSlots[0].from).format("HH:mm");
  const maxTime = moment(filteredSlots[0].to).format("HH:mm");

  return [minTime, maxTime];
};

export const isTimeInRange = (time, minTime, maxTime) => {
  return (
    moment(time, "HH:mm") >= moment(minTime, "HH:mm") &&
    moment(time, "HH:mm") <= moment(maxTime, "HH:mm")
  );
};
