// @ts-nocheck
import axios from "axios";
import { STATUS_CODE } from "./api.status-code";
import { withError, withData } from "./api.helper";

const BASE_URL = "https://rms-prod.algorithm.com.np/api";

let isRefreshing = false;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    key: process.env.REACT_APP_API_KEY,
    type: "customer",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return withData(response.data);
  },
  async (error) => {
    if (error.message === STATUS_CODE.NETWORK_ERROR) {
      return withError(error.message);
    }

    const {
      response: { status, data },
    } = error;

    if (status === STATUS_CODE.UNAUTHORIZED) {
      return handle401Error(error);
    }

    return withError(data);
  }
);

const handle401Error = (error) => {
  if (error.response.data.message === "Unauthenticated.") {
    if (!isRefreshing) {
      isRefreshing = true;

      localStorage.removeItem("token");
    }
  }
  return error?.response?.data
    ? error.response.data
    : "There was some problem connecting with server.Try again";
};

export const get = (url, params = {}) => {
  return axiosInstance({
    method: "get",
    url,
    params,
  });
};

export const post = (url, data) => {
  return axiosInstance({
    method: "post",
    url,
    data,
  });
};

export const put = (url, data) => {
  return axiosInstance({
    method: "put",
    url,
    data,
  });
};

export const patch = (url, data) => {
  return axiosInstance({
    method: "patch",
    url,
    data,
  });
};

export const remove = (url, params = {}) => {
  return axiosInstance({
    method: "delete",
    url,
    params,
  });
};
