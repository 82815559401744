import React from "react";
import { toast } from "react-toastify";

export const SuccessMessage = (props) => {
  const { message } = props;

  return (
    <div className="d-flex">
      <i className="icon ion-md-checkmark-circle text-normal" />
      <span className="pl-2"> {message}</span>
    </div>
  );
};

export const InfoMessage = (props) => {
  const { message } = props;

  return (
    <div className="d-flex">
      <i className="icon ion-md-information-circle text-normal" />
      <span className="pl-2 font-popin"> {message}</span>
    </div>
  );
};

export const ErrorMessage = (props) => {
  const { message } = props;

  return (
    <div className="d-flex">
      <i className="icon ion-md-remove-circle text-normal" />
      <span className="pl-2"> {message}</span>
    </div>
  );
};

export const WarnMessage = (props) => {
  const { message } = props;

  return (
    <div className="d-flex">
      <i className="icon ion-md-warning text-normal" />
      <span className="pl-2"> {message}</span>
    </div>
  );
};

const Toast = {
  success: function (message) {
    return toast.success(<SuccessMessage message={message} />);
  },
  info: function (message) {
    return toast.info(<InfoMessage message={message} />);
  },
  error: function (message) {
    return toast.error(<ErrorMessage message={message} />);
  },
  warn: function (message) {
    return toast.warn(<WarnMessage message={message} />);
  },
};

export default Toast;
