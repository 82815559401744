import React from "react";
import { CloseIcon } from "./Icons";

const Modal = ({ open, setOpen, children, hasCloseIcon }) => {
  return (
    <div
      style={{
        display: open ? "flex" : "none",
        position: "fixed",
        zIndex: 1,
        paddingTop: 100,
        left: 0,
        top: 0,
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: "auto",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      className="sm:items-end items-center"
    >
      <div className="modal-content bg-white p-5 items-center justify-center relative modal-content sm:p-2 w-1/2 sm:w-full rounded-sm">
        {hasCloseIcon && (
          <span
            className="close absolute right-5 top-3 cursor-pointer z-40"
            onClick={() => setOpen(false)}
          >
            <CloseIcon color={"#313a4b"} size={"20"} />
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  hasCloseIcon: true,
};

export default Modal;
