import { SET_LOGOUT, SET_USER } from "./user.type";

const initialState = {};
const user = (state = initialState, action) => {
  if (action.type === SET_USER) {
    return {
      ...action.payload,
      isAuthenticated: true,
    };
  }

  if (action.type === SET_LOGOUT) {
    return {
      isAuthenticated: false,
    };
  }

  return state;
};

export default user;
