import { SET_USER, SET_LOGOUT } from "./user.type";

export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  };
};

export const setLogout = () => {
  return {
    type: SET_LOGOUT,
  };
};
