import { useState, useEffect } from "react";

const useResize = () => {
  const [isSm, setSm] = useState(window.innerWidth < 640);

  useEffect(() => {
    const resize = window.addEventListener("resize", function (event) {
      if (window.innerWidth < 640) {
        return setSm(true);
      }
      return setSm(false);
    });

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return { isSm };
};

export default useResize;
