import {
  VeganIcon,
  VeryHotIcon,
  GlutanFreeIcon,
  SlightlySpicyIcon,
} from "components/Icons";
import { isEmpty } from "./form.helper";

const tags = [
  {
    name: "vegetarian",
    icon: () => <VeganIcon />,
  },
  {
    name: "slightly_spiced",
    icon: () => <SlightlySpicyIcon />,
  },
  {
    name: "glutan_free",
    icon: () => <GlutanFreeIcon />,
  },
  {
    name: "very_hot",
    icon: () => <VeryHotIcon />,
  },
];

export const getTags = (tagLabels) => {
  if (isEmpty(tagLabels)) return [];

  const newLabels = tagLabels.map((label) => {
    return label.value;
  }); // convert labels to array of strings ["Vegan", "Slightly Spiced"]

  const filteredTags = tags.filter((tag) => {
    return newLabels.includes(tag.name);
  }); // filter tags based on labels

  return filteredTags;
};
