import React from "react";
import * as Yup from "yup";

import Form from "components/Form";
import Toast from "components/Toast";

import { forgetPassword } from "api/request";
import { getSchema, getInitialValues } from "helper/form.helper";
import { useHistory } from "react-router-dom";

export default function ForgetPassword() {
  const history = useHistory();
  const formFields = [
    {
      name: "email",
      placeholder: "Enter your email",
      type: "email",
      initialValues: "",
      required: true,
      validation: Yup.string()
        .email("Invalid email address")
        .required("Required field"),
    },
  ];

  const handleSubmit = async (values) => {
    const { data, error } = await forgetPassword(values);

    if (!error && data) {
      Toast.info("Please check your email to reset your password");

      return history.push("/auth/login");
    }

    return Toast.error(error.errors.join(" "));
  };

  return (
    <div className="container p-10 flex justify-center items-center">
      <div className="w-1/2 sm:w-full">
        <h2 className="font-semibold text-center text-xl">
          Reset your password
        </h2>

        <Form
          isEditable={true}
          initialValues={getInitialValues(formFields)}
          Schema={getSchema(formFields)}
          fields={formFields}
          onSubmit={handleSubmit}
          buttonTitle={"Submit"}
        />
      </div>
    </div>
  );
}
