import React from "react";
import * as Yup from "yup";
import { login } from "../api/request";
import Form from "components/Form";
import { setUser } from "user/user.action";
import { useDispatch } from "react-redux";
import { getSchema, getInitialValues } from "helper/form.helper";
import { Link, useHistory } from "react-router-dom";
import Toast from "components/Toast";

export default function Login({ onSuccess }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const formFields = [
    {
      name: "email",
      placeholder: "Email",
      type: "email",
      initialValues: "",
      required: true,
      validation: Yup.string()
        .email("Invalid email address")
        .required("Required field"),
    },
    {
      name: "password",
      type: "password",
      placeholder: "Password",
      initialValues: "",
      required: true,
      validation: Yup.string()
        .required("Required field")
        .min(6, "Password must be at least 6 characters"),
    },
  ];

  const handleSubmit = async (values) => {
    const { data, error } = await login(values);

    if (!error && data.token) {
      dispatch(setUser(data));
      localStorage.setItem("token", data.token);

      if (onSuccess) {
        return onSuccess();
      }
      history.replace("/");

      return window.location.reload("");
    }

    return Toast.error("Invalid email or password");
  };

  return (
    <div className="flex justify-center items-center mt-5">
      <div className="w-full mt-5">
        <h2 className="font-semibold text-center text-xl text-red-light font-balo">
          Already have an account?
        </h2>

        <Form
          isEditable={true}
          initialValues={getInitialValues(formFields)}
          Schema={getSchema(formFields)}
          fields={formFields}
          onSubmit={handleSubmit}
          buttonTitle={"Login"}
        />

        <span className="flex justify-center items-center">
          <Link
            className="font-balo text-center font-semibold text-gray-darkest"
            to="/forgetpassword"
          >
            Forgot your password?
          </Link>
        </span>
      </div>
    </div>
  );
}
